// src/utils/decimalFormatter.js
import BigNumber from "bignumber.js";
const currencyRates = {
  USD: 1,
  INR: 85.54, // later fetch this from backend dynamically or store in LS
};

// const currencySymbols = {
//   USD: "$",
//   INR: "₹",
// };

// export function formatDecimal(value, fixedDecimalPlaces = 5) {
//   if (value === null || value === undefined || isNaN(value) || value === "") {
//     return "";
//   }
//   const num = new BigNumber(value);

//   // Format to the fixed number of decimal places
//   const formattedValue = num.toFixed(fixedDecimalPlaces);

//   // Add commas for thousands separators
//   const parts = formattedValue.split(".");
//   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

//   // Remove unnecessary trailing zeros and the decimal point if there are no decimal places
//   const trimmedValue = parts
//     .join(".")
//     .replace(/(\.\d*?[1-9])0+$/, "$1")
//     .replace(/\.0*$/, "");

//   return trimmedValue;
// }

export const getUserCurrency = () => {
  return localStorage.getItem("user_currency") || "USD";
};

const getUserCurrencyInternal = () => {
  return localStorage.getItem("user_currency") || "USD";
};

export const setUserCurrency = (currency) => {
  localStorage.setItem("user_currency", currency);
};

export function formatDecimal(value, fixedDecimalPlaces = 5, options = {}) {
  if (value === null || value === undefined || isNaN(value) || value === "") {
    return "";
  }

  const userCurrency = getUserCurrencyInternal();
  const conversionRate = currencyRates[userCurrency] || currencyRates["USD"];
  // const symbol = currencySymbols[userCurrency] || "";

  const num = new BigNumber(value).multipliedBy(conversionRate);
  const formattedValue = num.toFixed(fixedDecimalPlaces);

  const parts = formattedValue.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const trimmedValue = parts
    .join(".")
    .replace(/(\.\d*?[1-9])0+$/, "$1")
    .replace(/\.0*$/, "");

  // const showSymbol = options.showSymbol ?? true;

  return trimmedValue;
}

export function convertToUSD(value, rate = 85.54, precision = 10) {
  if (value === null || value === undefined || isNaN(value) || value === "") {
    return "";
  }

  const usdValue = new BigNumber(value).dividedBy(rate);

  // Return as a string with desired precision (default 10)
  return usdValue.toFixed(precision); // now: 7481.879822305
}

// Function to get the number of decimal places
const getDecimalPlaces = (num) => {
  const parts = num.toString().split(".");
  return parts.length > 1 ? parts[1].length : 0;
};

// Function to format the decimal based on the entry price's decimal places
export function formatTakeProfit(entryPrice, takeProfit) {
  const decimalPlaces = getDecimalPlaces(entryPrice);
  const num = new BigNumber(takeProfit);
  return num.toFixed(decimalPlaces).replace(/(\.[0-9]*[1-9])0+|\.0*$/, "$1");
}
