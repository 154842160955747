import { myApi } from "./api";
import {
  cancleOrderFx,
  closeOrderFutures,
  createOrderFutures,
  modifyOrderFutures,
  cancelOrderFutures,
} from "../components/constant/Api";

export const FuturesApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrderFutures: builder.mutation({
      query: (post) => ({
        url: createOrderFutures,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    modifyOrderFutures: builder.mutation({
      query: (post) => ({
        url: modifyOrderFutures,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    closeOrderFutures: builder.mutation({
      query: (post) => ({
        url: closeOrderFutures,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    cancelFuturesOrders: builder.mutation({
      query: (post) => ({
        url: cancelOrderFutures,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
  }),
});

export const {
  useCreateOrderFuturesMutation,
  useCloseOrderFuturesMutation,
  useModifyOrderFuturesMutation,
  useCancelFuturesOrdersMutation,
} = FuturesApi;
