import { baseUrl, fxUrl } from "./BaseUrl";

export const loginApi = baseUrl + "/login";
export const changePasswordApi = baseUrl + "/change-password";
export const registerApi = baseUrl + "/register";
export const sendOtp = baseUrl + "/send-otp";
export const forgotOtp = baseUrl + "/forgot-otp";
export const forgotUsernameOtp = baseUrl + "/forgot-username-otp";
export const getUsername = baseUrl + "/get-username";
export const checkOtp = baseUrl + "/check-otp";
export const userInfo = baseUrl + "/get-user-details";
export const widgetPermissions = baseUrl + "/get-widget-permissions";
export const changeStatusAuth = baseUrl + "/verify-google-code";
export const loginVerify2fa = baseUrl + "/verify-login-code";
export const googleCodeGenerate = baseUrl + "/generate-google-code";
export const tickitLists = baseUrl + "/ticket-list-by-user";
export const tikitChatByID = baseUrl + "/tikit-chat-by-id";
export const tikitChatByIDSingle = baseUrl + "/tikit-chat-by-id-single";
export const submitTickit = baseUrl + "/submit-tickit";
export const addChatsApi = baseUrl + "/add-chats";
export const updateSegment = baseUrl + "/update-trading-segment";
export const getCodeKyx = baseUrl + "/send-code-kyc";
export const verifyCodeKyx = baseUrl + "/check-code-kyc";
export const submitKyc = baseUrl + "/submit-kyc";
export const userActivity = baseUrl + "/user-activity";
export const pairInfo = baseUrl + "/pair-info";
export const userCommission = baseUrl + "/user-commission";
export const getActiveTabs = baseUrl + "/crypto-data-by-tab";
export const getAllWallets = baseUrl + "/wallet-coins-balance";
export const transferAssets = baseUrl + "/transfer-assets";
export const paymentToken = baseUrl + "/get-payment-token";
export const paymentRequest = baseUrl + "/payment-request";
export const paymentCountry = baseUrl + "/payment-country";
export const paymentMethods = baseUrl + "/payment-methods";
export const accountDetails = baseUrl + "/account-details";
export const personalDetails = baseUrl + "/personal-details";
export const getAccountDetails = baseUrl + "/account-details";
export const withdrawalRequest = baseUrl + "/withdrawal-request-admin";
export const createOrderClickCrypto = baseUrl + "/create-order-margin";
export const createCryptoFuturesOrder = baseUrl + "/create-crypto-future-order";
export const closeOrderCrypto = baseUrl + "/close-order-margin";
export const closeAllPositionsCrypto = baseUrl + "/close-all-positions-crypto";
export const closeOrderCryptoFutures = baseUrl + "/close-order-margin-future";
export const partialCloseOrderCrypto = baseUrl + "/partial-close-order-margin";
export const partialCloseOrderCryptoFutures =
  baseUrl + "/partial-close-order-margin-futures";
export const cancleOrderCrypto = baseUrl + "/cancle-order-margin";
export const cancelOrderCryptoFutures = baseUrl + "/cancel-order-margin-future";
export const createOrder = baseUrl + "/place-order";
export const cancleOrder = baseUrl + "/cancle-order";
export const modifyOrderClickCryptoMargin =
  baseUrl + "/modify-order-crypto-click";
export const cryptoTradeHistory = baseUrl + "/crypto-trade-history";
export const cryptoTransHistory = baseUrl + "/crypto-trans-history";
export const createOrderFutures = fxUrl + "/create-future-order";
export const closeOrderFutures = fxUrl + "/close-future-order";
export const modifyOrderFutures = fxUrl + "/update-future-order";
export const cancelOrderFutures = fxUrl + "/cancel-future-order";
export const createOrderClick = fxUrl + "/create-order-fx-click";
export const modifyOrderClick = fxUrl + "/modify-order-fx-click";
export const closePosition = fxUrl + "/close-position-fx";
export const closeAllPositions = fxUrl + "/close-all-positions-fx";
export const partialClosePosition = fxUrl + "/partial-close-position-fx";
export const cancleOrderFx = fxUrl + "/cancle-position-fx";
export const fetchUserLeverage = fxUrl + "/user-leverage-list";
export const updateUserLeverage = fxUrl + "/update-leverage-user";
export const getSymbolInfo = fxUrl + "/get-forex-symbol-info";
export const forexTradeHistory = fxUrl + "/forex-trade-history";
export const resetPassword = baseUrl + "/reset-password";
export const getCryptoPortfolio = baseUrl + "/crypto-portfolio";
export const getForexPortfolio = fxUrl + "/user-forex-portfolio";
export const equityTradeHistory = baseUrl + "/equity-trade-history";
export const getSelectedLeverage = fxUrl + "/get-selected-leverage";
export const runScript = baseUrl + "/run-script";
export const createMasterProfile = baseUrl + "/master-profile";
export const updateMasterProfile = baseUrl + "/master-profile-update";
export const masters = baseUrl + "/masters";
export const createSubscription = baseUrl + "/create-subscription";
export const subscribedMasters = baseUrl + "/copy-masters";
export const copiers = baseUrl + "/copiers";
export const copierBalanceHistory = baseUrl + "/copiers-balance-history";
export const addInvestment = baseUrl + "/add-investment";
export const decreaseInvestment = baseUrl + "/decrease-investment";
export const stopSubscription = baseUrl + "/stop-subscription";
export const mastersMetrics = baseUrl + "/masters-metrics";
export const getCopierCloseTrades = baseUrl + "/copiers-trades";
export const getCopierOpenTrades = baseUrl + "/copiers-open-orders";
export const cryptoTradeMarginHistory =
  baseUrl + "/crypto-trade-margin-history";
export const getMastersCloseTrades = baseUrl + "/master-trades";
export const getMastersOpenTrades = baseUrl + "/master-open-orders";
export const masterBalanceHistory = baseUrl + "/master-balance-history";
export const addRemoveSymbol = baseUrl + "/add-remove-symbol-watchlist";
export const getSymbols = baseUrl + "/symbols";
