// context/CurrencyContext.js
import React, { createContext, useContext, useState, } from "react";
import { getUserCurrency, setUserCurrency } from "../util/decimalFormatter";

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState(getUserCurrency());

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
    setUserCurrency(newCurrency);
  };

  return (
    <CurrencyContext.Provider
      value={{ currency, setCurrency: handleCurrencyChange }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => useContext(CurrencyContext);
