import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "./AuthContext";
import Login from "./components/auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import Home from "./components/pages/Home";
import { Toaster } from "react-hot-toast";
import Register from "./components/auth/Register";
import OnBoarding from "./components/pages/OnBoarding";
import Dashboard from "./components/widgets/Dashboard";
import CryptoExchange from "./components/pages/CryptoExchange";
import Forgot from "./components/auth/Forgot";
import Kyc from "./components/pages/Kyc";
import Support from "./components/pages/Support";
import Chatting from "./components/pages/Chatting";
import NotFound from "./components/widgets/404";
import ForexExchange from "./components/pages/ForexExchange";
import CryptoAssets from "./components/pages/CryptoAssets";
import GoogleAuthentication from "./components/pages/GoogleAuthentication";
import CryptoTradeHistory from "./components/pages/CryptoTradeHistory";
import CryptoTransactionHistory from "./components/pages/CryptoTransactionHistory";
import MobileChart from "./components/partials/MobileChart";
import EquityExchange from "./components/pages/EquityExchange";
import ForexMobile from "./components/partials/ForexMobile";
import UserActivity from "./components/pages/UserActivity";
import ChangePassword from "./components/pages/ChangePassword";
import ForexExchangeEquity from "./components/pages/ForexExchangeEquity";
import TradingViewCryptoMobile from "./components/partials/TradingViewCryptoMobile";
import ForgotUsername from "./components/auth/ForgotUsername";
import { setUserSettings } from "./redux/userSlice";
import { useGetallUserQuery } from "./redux/userApi";
import Loader from "./util/loader";
import PermissionNot from "./components/widgets/PermissionNot";
import Profile from "./components/pages/Profile";
import TradingTerminal from "./components/pages/TradingTerminal";
import TradingExchange from "./components/pages/TradingExchnage";
import Masters from "./components/pages/Masters";
import UploadScript from "./components/pages/UploadScript";
import MasterProfile from "./components/pages/masterProfile";
import CopierArea from "./components/pages/CopierArea";
import SubScriptionSetup from "./components/pages/SubScriptionSetup";
import MasterProfileSetup from "./components/pages/MasterProfileSetup";
import CopierMasters from "./components/pages/CopierMasters";
import EquityFutures from "./components/pages/EquityFutures";
import CryptoExchangeFutures from "./components/pages/CryptoExchangeFutures";
import CopyPerformance from "./components/pages/CopyPerformance";

const App = () => {
  const jwtToken = localStorage.getItem("jwtToken");
  const { login } = useAuth();
  const dispatch = useDispatch();
  const shouldFetchUser = !!jwtToken; // Only fetch if the token exists
  const { data, isLoading } = useGetallUserQuery(undefined, {
    skip: !shouldFetchUser, // Skip the query if the token doesn't exist
  });

  useEffect(() => {
    if (jwtToken) {
      login();
      if (data) {
        console.log(data, "datakk");
        dispatch(setUserSettings(data)); // Dispatch action to set user settings in Redux
      }
    }

    // Add a beforeunload event to handle browser/tab close
    const handleBeforeUnload = (event) => {
      // Check if the page navigation type is not a reload/refresh
      const navigationType =
        performance.getEntriesByType("navigation")[0]?.type;
      if (navigationType !== "reload") {
        // Only remove token when the tab or browser is being closed, not refreshed
        // localStorage.removeItem("jwtToken");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [login, jwtToken, data, dispatch]);

  if (isLoading) {
    // Replace 'Loader' with your actual loading component
    return (
      <div className="loader-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/masters" element={<Masters />} />
          <Route path="/copier-performance" element={<CopyPerformance />} />
          <Route
            path="/master-profile/:masterUserId"
            element={<MasterProfile />}
          />
          <Route path="/register" element={<Register />} />

          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/forgot-username" element={<ForgotUsername />} />
          <Route
            path="/onboarding"
            element={<ProtectedRoute component=<OnBoarding /> />}
          />
          <Route
            path="/exchange"
            element={<ProtectedRoute component=<TradingExchange /> />}
          />
          <Route
            path="/copier-area/:copierUserId/:masterUserId"
            element={<ProtectedRoute component=<CopierArea /> />}
          />
          <Route
            path="/copier-masters"
            element={<ProtectedRoute component=<CopierMasters /> />}
          />
          <Route path="/subscription-setup" element={<SubScriptionSetup />} />
          <Route
            path="/master-profile-setup"
            element={<ProtectedRoute component={<MasterProfileSetup />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute component=<Profile /> />}
          />
          <Route
            path="/trading-dashboard"
            element={<ProtectedRoute component=<TradingTerminal /> />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component=<Dashboard /> />}
          />
          {data?.is_crypto_enabled === "yes" ? (
            <Route
              path="/crypto/:firstCoin/:secondCoin"
              element={<ProtectedRoute component=<CryptoExchange /> />}
            />
          ) : (
            <Route
              path="/crypto/:firstCoin/:secondCoin"
              element={<ProtectedRoute component=<PermissionNot /> />}
            />
          )}
          {data?.is_crypto_enabled === "yes" ? (
            <Route
              path="/crypto-futures/:firstCoin/:secondCoin"
              element={<ProtectedRoute component=<CryptoExchangeFutures /> />}
            />
          ) : (
            <Route
              path="/crypto-futures/:firstCoin/:secondCoin"
              element={<ProtectedRoute component=<PermissionNot /> />}
            />
          )}
          <Route
            path="/mobile-crypto/:firstCoin/:secondCoin"
            element={<TradingViewCryptoMobile />}
          />
          <Route path="/mobile-forex/:symbol" element={<ForexMobile />} />
          <Route
            path="/stocks/:symbol"
            element={<ProtectedRoute component=<EquityExchange /> />}
          />
          {data?.is_fx_enabled === "yes" ? (
            <Route
              path="/forex/:symbol"
              element={<ProtectedRoute component=<ForexExchange /> />}
            />
          ) : (
            <Route
              path="/forex/:symbol"
              element={<ProtectedRoute component=<PermissionNot /> />}
            />
          )}
          {data?.is_equity_enabled === "yes" ? (
            <Route
              path="/forex-equity/:symbol"
              element={<ProtectedRoute component=<ForexExchangeEquity /> />}
            />
          ) : (
            <Route
              path="/forex-equity/:symbol"
              element={<ProtectedRoute component=<PermissionNot /> />}
            />
          )}

          {data?.is_equity_enabled === "yes" ? (
            <Route
              path="/futures-equity/:symbol"
              element={<ProtectedRoute component=<EquityFutures /> />}
            />
          ) : (
            <Route
              path="/futures-equity/:symbol"
              element={<ProtectedRoute component=<EquityFutures /> />}
            />
          )}

          <Route
            path="/verify-identity"
            element={<ProtectedRoute component=<Kyc /> />}
          />
          <Route
            path="/script-upload"
            element={<ProtectedRoute component=<UploadScript /> />}
          />
          <Route
            path="/support"
            element={<ProtectedRoute component=<Support /> />}
          />
          <Route
            path="/change-password"
            element={<ProtectedRoute component=<ChangePassword /> />}
          />
          <Route
            path="/account"
            element={<ProtectedRoute component=<CryptoAssets /> />}
          />
          <Route
            path="/two-factory"
            element={<ProtectedRoute component=<GoogleAuthentication /> />}
          />
          <Route
            path="/trade-history"
            element={<ProtectedRoute component=<CryptoTradeHistory /> />}
          />
          <Route
            path="/transaction-history"
            element={<ProtectedRoute component=<CryptoTransactionHistory /> />}
          />
          <Route
            path="/activity"
            element={<ProtectedRoute component=<UserActivity /> />}
          />
          <Route
            path="/Chatting/:ticketId/:status"
            element={<ProtectedRoute component=<Chatting /> />}
          />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Toaster position="top-right" />
    </div>
  );
};

export default App;
