import React, { useEffect, useRef, memo } from "react";
import { useParams } from "react-router-dom";

function TradingViewFuturesWidget({ theme = "dark" }) {
  const container = useRef(null);
  const { symbol } = useParams();

  useEffect(() => {
    initChart();
  }, [symbol, theme]); // Ensure this effect runs only once when the component mounts


  const getTradingViewSymbol = (symbol) => {
    if (!symbol) return "CME_MINI:ES1!"; // Default fallback
  
    const match = symbol.match(/([A-Z]+)([FGHJKMNQUVXZ])(\d)/);
    if (!match) return "CME_MINI:ES1!"; // If parsing fails, use fallback
  
    const [, baseSymbol, monthCode, yearDigit] = match;
  
    // Futures Expiry Month Codes (For TradingView)
    const tradingViewMonthCodes = {
      F: "F", G: "G", H: "H", J: "J", K: "K", M: "M",
      N: "N", Q: "Q", U: "U", V: "V", X: "X", Z: "Z"
    };
  
    const tradingViewMonth = tradingViewMonthCodes[monthCode];
  
    const currentYear = new Date().getFullYear();
    const fullYear = parseInt(currentYear.toString().slice(0, -1) + yearDigit);
  
    let exchange = "CME_MINI"; // Default Exchange
  
    // 🔹 Fix for Gold, Silver, and Metals (COMEX)
    if (baseSymbol.startsWith("GC") || baseSymbol.startsWith("SI")) {
      exchange = "COMEX";
    }
  
    // 🔹 Fix for Dow Jones Futures (CBOT)
    if (baseSymbol.startsWith("YM")) {
      exchange = "CBOT"; // ✅ Corrected for YMH5 (Dow Jones)
    }
  
    return `${exchange}:${baseSymbol}${tradingViewMonth}${fullYear}`;
  };
  
  const initChart = () => {
    const tradingViewSymbol = getTradingViewSymbol(symbol);
    console.log("tradingViewSymbol",tradingViewSymbol)
    const pair = symbol;
    const currentContainer = container.current;
    currentContainer.innerHTML = "";

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.crossOrigin = "anonymous";
    script.innerHTML = JSON.stringify({
      autosize: true,
      timezone: "America/New_York",
      symbol: `${tradingViewSymbol}`,
      interval: "D",
      theme: theme,
      style: "8",
      locale: "en",
      enable_publishing: false,
      withdateranges: true,
      hide_side_toolbar: false,
      allow_symbol_change: true,
      calendar: false,
      support_host: "https://www.tradingview.com",
      // height: 320,
      // width: "100%",
    });

    currentContainer.appendChild(script);
  };

  return <div ref={container} id="tv_chart_container"></div>;
}

export default memo(TradingViewFuturesWidget);
