import React from "react";
import Navbar from "../widgets/Navbar";

const CopyPerformance = () => {
  return (
    <div>
      <Navbar />
      <section className="p70  pt-md-5 pb-md-5">
      <div className="container ">
       <div className="p-3 p-md-4  bg2 br20 mb-4  d-flex     align-items-center">
              <img className="user_img" src="img/user.jpg" alt="user" />
              <div className="pl-4 mr-auto">
               <h4 className="mb-1">Jeppe Kirk Bonde ★</h4>
               <small>@JeppeKirkBonde</small>
               <p className="mt-2 mb-0"> <img className="flag_img mr-2" src="img/flag.png" alt="flag" />Top 10 Most Copied</p>
               
            </div>
            <button className="btn" type="button"><i className="bi bi-copy mr-2"></i> Copy</button>
            </div>
         <div className="row ">
         <div className="col-lg-5 mb-4">
            <div className="p-3 p-md-4 bg2 br20  h100">
               <h6 className="mb-2">About JeppeKirkBonde</h6>
               <p>The most copied Popular Investor on eToro. 30,000+ investors copy with over $100 million.</p>
               <hr/>
               <div className="row ">
               <div className="col-lg-6">
                <h2 className="green_color">32.8K</h2>
                <p className="mb-0">Copiers</p>
               </div>
               <div className="col-lg-6">
               <h2 className="green_color">304.5K</h2>
               <p className="mb-0">Followers</p>
               </div>
            </div>
            <hr/>
            <div className="row mt-3">
               <div className="col-auto mr-auto"> Returns Today</div>
               <div className="col-auto green_color"> 0.00 <i className="bi bi-caret-up-fill"></i></div>
            </div>
            <div className="row mt-3">
               <div className="col-auto mr-auto"> Returns This Week</div>
               <div className="col-auto green_color"> 0.00 <i className="bi bi-caret-up-fill"></i></div>
            </div>
            <div className="row mt-3">
               <div className="col-auto mr-auto"> Return This Month</div>
               <div className="col-auto green_color"> 0.00 <i className="bi bi-caret-up-fill"></i></div>
            </div>
            <div className="row mt-3">
               <div className="col-auto mr-auto"> Since Inception</div>
               <div className="col-auto green_color"> 0.00 <i className="bi bi-caret-up-fill"></i></div>
            </div>
            </div>
         </div>
         <div className="col-lg-7 mb-4">
            <div className="p-3 p-md-4 bg2 br20    ">
            <div className="d-flex mb-4">
               <h6 className="mb-0 mr-auto">Performance</h6>
               <select  className="lang-control form-control form-select mr-0">
                <option  selected="">All</option> 
                </select>
               </div>
               <img className="w100" src="img/chart.jpg" alt="chart" />
              
            </div>
         </div>
      </div>
      <div className="p-3 p-md-4  bg2 br20 mb-4">
         <h6 className="mb-3">Performance</h6> 
          <table className="table table-primary table_striped_seces">
          <tr>
          <th>
            </th> 
              <th>Jan</th>
              <th>Feb</th>
              <th>Mar</th>
              <th>Apr</th>
              <th>May</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Aug</th>
              <th>Sep</th>
              <th>Oct</th>
              <th>Nov</th>
              <th>Dec</th>
              <th></th> 
            </tr>
            <tbody>
            <tr>
              <td>2025</td>
              <td><span className="green_color">4.52%</span></td>
              <td><span className="read_color">-2.27%</span></td>
              <td><span className="read_color"> -0.41%</span></td>
              <td><span className="green_color"></span></td>
              <td><span className="green_color"></span></td>
              <td><span className="green_color"></span></td>
              <td><span className="green_color"></span></td>
              <td><span className="green_color"></span></td>
              <td><span className="green_color"></span></td>
              <td><span className="green_color"></span></td>
              <td><span className="green_color"></span></td>
              <td><span className="green_color"></span></td> 
              <td className="text-right">1.73%</td>
               
              
            </tr>
            <tr>
              <td>2024</td>
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="green_color">3.59%</span></td> 
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="green_color">3.59%</span></td> 
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="green_color">3.59%</span></td> 
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="green_color">3.59%</span></td> 
              <td className="text-right">20.95% </td> 
            </tr>
            <tr>
              <td>2023</td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="read_color">-1.13%</span></td>
              <td><span className="green_color">4.71%</span></td>
              <td><span className="read_color">-1.13%</span></td>
              <td className="text-right"> 23.92%</td> 
            </tr>
            </tbody>
          </table>
            </div>
            <div className="row ">
            <div className="col-lg-4 mb-4">
            <div className="p-3 p-md-4 bg2 br20   h100 ">
            <div className="d-flex mb-4">
              <div className="">
               <h6 className=" mr-auto">Breakdown</h6>
               <p className="mb-0">This breakdown includes 97.5% of this Portfolio</p>
               </div>
               <select  className="lang-control form-control form-select mb-1 ml-2 mr-0 mt-auto">
                <option  selected="">Asset Type</option> 
                <option>Stocks</option> 
                <option>FX</option> 
                <option>Crypto</option> 
                </select>
               </div>
               <img className="w70 m-auto d-block" src="img/breakdown.png" alt="chart" />
              
            </div>
         </div>
             <div className="col-lg-4 mb-4">
            <div className="p-3 p-md-4 bg2 br20  h100">
          
               <h6 className="">Portfolio Risk (1Y)</h6>
               <div class="buyselll_box mt-3">
                <ul class="nav buy_sell_tab  d-flex ">
                  <li class="nav-item w100"><button class="active w100">Risk Score</button></li>
                  <li class="nav-item w100 "><button class="w100">Risk Contribution</button></li></ul>
                  </div> 
              
               <img className="w100 " src="img/chart2.jpg" alt="chart" />
            <hr/>
            <div className="row   mt-4 pt-2">
               <div className="col-auto mr-auto"> Avg. Risk Score (last 12M)</div>
               <div className="col-auto green_color"> 4  </div>
            </div><hr/>
            <div className="row  ">
               <div className="col-auto mr-auto"> Weekly Max. Drawdown</div>
               <div className="col-auto green_color"> 5.34% <i className="bi bi-caret-up-fill"></i></div>
            </div>
            <hr/>
            <div className="row ">
               <div className="col-auto mr-auto"> Daily Max. Drawdown</div>
               <div className="col-auto green_color">3.19% <i className="bi bi-caret-up-fill"></i></div>
            </div>
            <hr/>
            <div className="row ">
               <div className="col-auto mr-auto"> Yearly Max. Drawdown</div>
               <div className="col-auto green_color">9.87% <i className="bi bi-caret-up-fill"></i></div>
            </div>
            </div>
         </div>
         <div className="col-lg-4 mb-4">
            <div className="p-3 p-md-4 bg2 br20    ">
            
               <h6 className="">ESG Score</h6>
               <p className="mb-0">Get insight into this portfolio's sustainability using its ESG score.</p>
               
               <img className="w70 m-auto d-block" src="img/hight.png" alt="" />
               <div class="row mt-3">
                <div class="col-md-4">
                  <h4 class="fw600">64</h4>
                  <p class="mb-0">Environment</p></div>
                  <div class="col-md-4">
                    <h4 class="fw600">57</h4>
                    <p class="mb-0">Social</p>
                  </div>
                  <div class="col-md-4">
                    <h4 class="fw600">57</h4>
                    <p class="mb-0">Governance</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
    </div>
  );
};

export default CopyPerformance;
