import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isCryptoEnabled: true,
    isFxEnabled: true,
    isEquityEnabled: true,
    userId: null,
    userData: null,
  },
  reducers: {
    setUserSettings: (state, action) => {
      state.isCryptoEnabled = action.payload.is_crypto_enabled;
      state.isFxEnabled = action.payload.is_fx_enabled;
      state.isEquityEnabled = action.payload.is_equity_enabled;
      state.userId = action.payload.id;
      state.user_name = action.payload.user_name;
      state.userData = { ...action.payload };
    },
  },
});

export const { setUserSettings } = userSlice.actions;

export default userSlice.reducer;
